import React, { FC, useContext, useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment-timezone';

import styles from '../chart.module.scss';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksDD, getTicksH } from '../utlity';
import { getSleepScores } from '../../../../services/api/chart';
import { getCurrentSleepScore } from '../../../../services/api/firestore';
import {
  CHARTS_SIZES,
  GRAPH_REVERSE_TOOLTIP_COORDINATE,
  SLEEP_CHART_REVERSE_TOOLTIP_COORDINATE,
} from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import firebase from 'firebase/app';
import SleepTooltip from './sleep-tooltip';
import { SleepComponent } from '../../../../interfaces/chart-results';
import { ChartToolTip } from '../chart-tooltip';

type SleepChartURData = {
  ts: string;
  start: string;
  end: string;
  value: number;
};

type SleepChartDto = {
  ts: number;
  start: number;
  end: number;
  value: number;
  components?: SleepComponent;
};

type Props = {
  start: string;
  end: string;
  urData?: SleepChartURData[];
  isPdf?: boolean;
  reference?: number;
  title?: string;
  setSleepData?: (data) => void;
};

export const SleepChart: FC<Props> = ({ start, end, urData, isPdf, reference, setSleepData, title }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<Array<SleepChartDto>>([]);
  const { patient } = useContext(PatientContext);
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  const totalMinutes = moment.utc(end).diff(moment.utc(start), 'minutes') + 1;
  const CARTESIAN_CHART_WIDTH = 1010;
  const MINUTE_PX_VALUE = CARTESIAN_CHART_WIDTH / totalMinutes;

  let unsubscribeSleepScore: () => void = () => {};

  const parseSleepScores = () => {
    const parsedData = urData?.map(entry => ({
      ts: moment(entry.ts).tz(patientTimezone).unix() * 1000,
      start: moment(entry.start).tz(patientTimezone).unix() * 1000,
      end: moment(entry.end).tz(patientTimezone).unix() * 1000,
      value: entry.value,
    }));

    if (parsedData) {
      setData(parsedData);
    }
  };

  const getAndParseScores = async () => {
    const { data } = await getSleepScores(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
      startDate: moment(start).tz(patientTimezone).format('YYYY-MM-DD'),
      endDate: moment(end).tz(patientTimezone).format('YYYY-MM-DD'),
      timezone: patientTimezone,
    });
    return data.points
      .filter(p => p.start && p.end)
      .map(p => {
        return {
          ...p,
          ts: moment.utc(p.ts).tz(patientTimezone).unix() * 1000,
          start: moment.utc(p.start).tz(patientTimezone).unix() * 1000,
          end: moment.utc(p.end).tz(patientTimezone).unix() * 1000,
        };
      });
  };

  const getSleepScoresAction = async patient => {
    let points = await getAndParseScores();
    unsubscribeSleepScore = getCurrentSleepScore(patient?.firebaseUid!, {
      next: async (Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          points = await getAndParseScores();
          const current = Snapshot.data();
          const { score, timestamp, startDateTime, endDateTime } = current as {
            score: number;
            timestamp: any;
            startDateTime: any;
            endDateTime: any;
          };
          // Need to have the points ordered by date so later I can easily get the latest sleep entry and compare it to the firebase current
          // if the firebase current is the same or earlier?than the last sleep entry, ignore it.
          const orderedPoints = points.sort((a, b) => moment(a.start).unix() - moment(b.start).unix());
          if (
            score >= 0 &&
            timestamp &&
            endDateTime &&
            startDateTime &&
            moment(startDateTime.seconds * 1000).isAfter(orderedPoints.slice(0).pop()?.end)
          ) {
            const ts = moment(timestamp.seconds * 1000)
              .tz(patientTimezone)
              .format();
            if (ts >= start && ts <= end) {
              setData(prevState => [
                ...(points.length ? points : prevState),
                {
                  ts:
                    moment(timestamp.seconds * 1000)
                      .tz(patientTimezone)
                      .unix() * 1000,
                  start:
                    moment(startDateTime.seconds * 1000)
                      .tz(patientTimezone)
                      .unix() * 1000,
                  end:
                    moment(endDateTime.seconds * 1000)
                      .tz(patientTimezone)
                      .unix() * 1000,
                  value: score,
                },
              ]);
            } else if (points.length) {
              setData(points);
            }
          } else {
            setData(points);
          }
          points = [];
        }
      },
    });
  };

  useEffect(() => {
    if (patient) {
      if (urData) {
        parseSleepScores();
      } else {
        getSleepScoresAction(patient);
        return (): void => {
          unsubscribeSleepScore();
        };
      }
    }
  }, [patient, start, end]);

  useEffect(() => {
    if (data.length > 0 && setSleepData) {
      setSleepData(data);
    }
  }, [data]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (value === 0 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 12}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          {value}
        </text>
      );
    }
    if (value === 100 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 49}
          y={y + 7}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Good {value}
        </text>
      );
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {value}
      </text>
    );
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active || !payload?.[0]?.payload) {
      return null;
    }
    const reverse = coordinate.x > SLEEP_CHART_REVERSE_TOOLTIP_COORDINATE;
    const data: SleepChartDto = payload?.[0].payload;
    const dates = payload ? [payload[0]?.payload?.start, payload[0]?.payload?.end] : [];
    const { totalRemSleepTime, totalLightSleepTime, totalDeepSleepTime, totalWakeTime } = data.components!;
    const sleepComponents = [
      { name: 'Awake', value: totalWakeTime },
      { name: 'Light', value: totalLightSleepTime },
      {
        name: 'Deep',
        value: totalDeepSleepTime,
      },
      { name: 'Rem', value: totalRemSleepTime },
    ];
    return data.value === 0 ? null : (
      <SleepTooltip
        data={{ score: data.value, sleepComponents: sleepComponents, start: dates[0], end: dates[1] }}
        reverse={reverse}
      />
    );
  };

  const customToolTipForTinnitus = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }

    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const yValue = payload ? payload[0]?.payload?.value : 'Unable to retrieve value';
    return (
      <ChartToolTip
        date={label}
        title={`${title}`}
        value={yValue}
        reverse={reverse}
        payload={payload ? payload[0] : []}
      />
    );
  };

  const calculateEntryXAxis = entryStartDate => {
    const diffStartAndSelected = moment(entryStartDate).diff(start, 'minutes');
    const pxDiff = MINUTE_PX_VALUE * diffStartAndSelected;
    return pxDiff + 66; // That 66 value is the offset produced by the YAxis, XAxis at 66 is the value of the startDate selected
  };

  const GradientBar = props => {
    const { x, y, height, end, start } = props;
    const minutesDiff = moment(end).diff(moment(start), 'minutes');
    const width = MINUTE_PX_VALUE * minutesDiff;

    return <rect width={width} height={height} x={calculateEntryXAxis(start)} y={y} fill='url(#d1)' />;
  };

  const checkTitle = () => {
    // Check if the title includes "AWAKE"
    if (title?.includes('AWAKE')) {
      return data?.map(i => {
        const { components } = i;
        return {
          ts: i.ts,
          start: moment(components?.sleepStartTime).unix() * 1000,
          end: moment(components?.sleepEndTime).unix() * 1000,
          value: components
            ? parseFloat(((components?.totalWakeTime / components?.duration) * 100).toFixed(2)) // Round to 2 decimals
            : 0,
        };
      });
    } else if (title?.includes('LIGHT')) {
      return data?.map(i => {
        const { components } = i;
        return {
          ts: i.ts,
          start: moment(components?.sleepStartTime).unix() * 1000,
          end: moment(components?.sleepEndTime).unix() * 1000,
          value: components
            ? parseFloat(((components?.totalLightSleepTime / components?.duration) * 100).toFixed(2)) // Round to 2 decimals
            : 0,
        };
      });
    } else if (title?.includes('DEEP')) {
      return data?.map(i => {
        const { components } = i;
        return {
          ts: i.ts,
          start: moment(components?.sleepStartTime).unix() * 1000,
          end: moment(components?.sleepEndTime).unix() * 1000,
          value: components
            ? parseFloat(((components?.totalDeepSleepTime / components?.duration) * 100).toFixed(2)) // Round to 2 decimals
            : 0,
        };
      });
    } else if (title?.includes('REM')) {
      return data?.map(i => {
        const { components } = i;
        return {
          ts: i.ts,
          start: moment(components?.sleepStartTime).unix() * 1000,
          end: moment(components?.sleepEndTime).unix() * 1000,
          value: components
            ? parseFloat(((components?.totalRemSleepTime / components?.duration) * 100).toFixed(2)) // Round to 2 decimals
            : 0,
        };
      });
    } else if (title?.includes('TOTAL')) {
      return data?.map(i => {
        const { components } = i;
        return {
          ts: i.ts,
          start: moment(components?.sleepStartTime).unix() * 1000,
          end: moment(components?.sleepEndTime).unix() * 1000,
          value: components ? Math.floor(components?.totalSleepTime / 60) : 0,
        };
      });
    } else {
      return data;
    }
  };

  return (
    <div id={`sleepChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>{title || 'SLEEP'}</span>
        {!urData && !title?.toLocaleLowerCase().includes('sleep') && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.sleep}`} type='up' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <BarChart
        className={styles.areaContainer}
        width={CHARTS_SIZES.CDP_WIDTH}
        height={CHARTS_SIZES.CDP_HEIGHT + 30}
        data={checkTitle()}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>
        {title ? (
          <YAxis tickSize={0} interval={0} tick={YAxisFormatter} />
        ) : (
          <YAxis hide={false} tick={YAxisFormatter} tickSize={0} interval={0} ticks={[0, 25, 50, 75, 100]} />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={unixTime => ''}
            dataKey='start'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='auto'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={unixTime => ''}
            dataKey='start'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='auto'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={unixTime => ''}
            dataKey='start'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='auto'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={0}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={true}
            tickSize={16}
            tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={2}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={3}
            dy={0}
            dx={5}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => {
              return moment.utc(unixTime).format('M/D');
            }}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={1}
            // hide={title?.includes('sleep')}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={4}
            dy={-25}
            dx={5}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => {
              return moment.utc(unixTime).format('M/D');
            }}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={1}
            // hide={title?.includes('sleep')}
          />
        )}
        {!!urData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />}
        <CartesianGrid stroke='#A3A6B3' />
        {!urData && <Tooltip content={title ? customToolTipForTinnitus : customToolTip} />}
        <Bar isAnimationActive={!isPdf} dataKey='value' fill='#417EB9' shape={<GradientBar />} />
      </BarChart>
    </div>
  );
};
